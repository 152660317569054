import React from 'react';

const Dev = () => {
    return (
        <div style={{textAlign: 'center', fontSize: '34px', color: 'red'}}>
            Блок находиться в разработке
        </div>
    );
};

export default Dev;