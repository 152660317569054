import React from 'react';
import Page404 from "../404/Page404";

const Hr = () => {
    return (
        <div>
            <Page404/>
        </div>
    );
};

export default Hr;